import "core-js/modules/es.array.push.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue";
const _hoisted_1 = {
  class: "banner-box"
};
const _hoisted_2 = ["src"];
const _hoisted_3 = {
  class: "home"
};
const _hoisted_4 = {
  class: "home-head"
};
const _hoisted_5 = ["onClick"];
const _hoisted_6 = {
  class: "home-item__left"
};
const _hoisted_7 = ["src"];
const _hoisted_8 = {
  class: "coin-name"
};
const _hoisted_9 = {
  class: "home-item__right"
};
const _hoisted_10 = {
  class: "home-item__price"
};
import { IMG_URL } from '@/config';
import { homePair, getBanner, getNotices } from '@/http';
import Tabbar from '@/components/Tabbar/Tabbar.vue';
import { router } from '@/router';
import { computed, ref } from 'vue';
import { useUserStore } from '@/stores/user';
import LineChart from '@/components/LineChart/LineChart.vue';
export default {
  __name: 'home',
  setup(__props) {
    const pairList = ref([]);
    const bannerList = ref([]);
    const notices = ref('');
    const userStore = useUserStore();
    const imgKey = computed(() => {
      return {
        'en_US': 'img',
        'fr_FR': 'fr_FR_img',
        'es_ES': 'es_ES_img',
        'it_IT': 'it_IT_img'
      }[userStore.lang];
    });
    const _getNotices = () => {
      notices.value = '';
      getNotices().then(({
        data
      }) => {
        data.data.forEach(item => {
          notices.value += item[{
            'en_US': 'name',
            'fr_FR': 'fr_FR_name',
            'es_ES': 'es_ES_name',
            'it_IT': 'it_IT_name'
          }[userStore.lang]] + '  ';
        });
      });
    };
    _getNotices();
    const toContract = item => {
      userStore.setSymbolData(item);
      router.push('/contract/' + item.id);
    };
    const jumpTo = path => {
      router.push(path);
    };
    const _homePair = () => {
      homePair().then(({
        data
      }) => {
        pairList.value = data.data;
        pairList.value.map((item, index) => {
          getCoinInfo(item.code, index);
        });
      });
    };
    _homePair();
    const _getBanner = () => {
      getBanner().then(({
        data
      }) => {
        bannerList.value = data;
      });
    };
    _getBanner();
    const getCoinInfo = (symbol, index) => {
      fetch('https://api.binance.com/api/v3/ticker/tradingDay?symbol=' + symbol).then(res => {
        return res.json();
      }).then(data => {
        pairList.value[index].price = parseFloat(data.lastPrice);
        pairList.value[index].priceChangePercent = data.priceChangePercent;
      });
    };
    return (_ctx, _cache) => {
      const _component_van_swipe_item = _resolveComponent("van-swipe-item");
      const _component_van_swipe = _resolveComponent("van-swipe");
      const _component_van_notice_bar = _resolveComponent("van-notice-bar");
      return _openBlock(), _createElementBlock(_Fragment, null, [_createElementVNode("div", _hoisted_1, [_createVNode(_component_van_swipe, {
        class: "banner-swipe",
        autoplay: 3000,
        "show-indicators": false
      }, {
        default: _withCtx(() => [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList(bannerList.value, item => {
          return _openBlock(), _createBlock(_component_van_swipe_item, {
            key: item.id
          }, {
            default: _withCtx(() => [_createElementVNode("img", {
              class: "banner-img",
              src: _unref(IMG_URL) + item[imgKey.value],
              alt: ""
            }, null, 8, _hoisted_2)]),
            _: 2
          }, 1024);
        }), 128))]),
        _: 1
      })]), _createVNode(_component_van_notice_bar, {
        mode: "closeable",
        "left-icon": "volume-o",
        background: "#FDE6E6",
        text: notices.value,
        onClick: _cache[0] || (_cache[0] = $event => jumpTo('/notices'))
      }, null, 8, ["text"]), _createElementVNode("div", _hoisted_3, [_createElementVNode("div", _hoisted_4, [_createElementVNode("span", null, _toDisplayString(_ctx.$t('currency_pair')), 1), _createElementVNode("span", null, _toDisplayString(_ctx.$t('latest_price')) + "/" + _toDisplayString(_ctx.$t('price_change_percentage')), 1)]), (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(pairList.value, item => {
        return _openBlock(), _createElementBlock("div", {
          class: "home-item",
          key: item.id,
          onClick: $event => toContract(item)
        }, [_createElementVNode("div", _hoisted_6, [_createElementVNode("img", {
          src: _unref(IMG_URL) + item.logo,
          alt: ""
        }, null, 8, _hoisted_7), _createElementVNode("div", _hoisted_8, [_createElementVNode("span", null, _toDisplayString(item.name) + "/", 1), _createTextVNode("USDT ")])]), _createElementVNode("div", null, [_createVNode(LineChart, {
          symbol: item.code,
          interval: "1m"
        }, null, 8, ["symbol"])]), _createElementVNode("div", _hoisted_9, [_createElementVNode("div", _hoisted_10, [_createElementVNode("p", null, _toDisplayString(item.price), 1), _createElementVNode("p", {
          class: _normalizeClass([Number(item.priceChangePercent) > 0 ? 'green-text' : 'red-text'])
        }, _toDisplayString(item.priceChangePercent) + "%", 3)])])], 8, _hoisted_5);
      }), 128))]), _createVNode(Tabbar, {
        active: 0
      })], 64);
    };
  }
};